@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

body {
  margin: 0;
  font-family: 'SF Pro Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'SF Pro Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.text-80 {
  font-size: 80px;
}

.text-50 {
  font-size: 50px;
}

.fade-in {
  animation: fade-in-animation 2.5s;
}

.instant-fade-in {
  animation: fade-in-animation 0s !important;
}

.header-bottom-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@keyframes fade-in-animation {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@layer utilities {
  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

.min-w-20 {
  min-width: 7rem;
}

* {
  overscroll-behavior-y: contain;
}

.w-38 {
  width: 9.5rem;
}

.h-38 {
  height: 9.5rem;
}

/* App.css */

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  /* Adjust width of scrollbar here */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #182C82;
  /* Adjust track color here */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.6);
  /* Adjust thumb color here */
  border-radius: 20px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #d6d4d4;
  /* Adjust thumb hover color here */
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* For compatibility */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.custom-shadow-play-button {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}